import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ConversationApi from "../api/conversationApi";
import MessageApi from "../api/messageApi";
import PlanApi from "../api/planApi";


export const thunkGetAdminReport = createAsyncThunk(
    'admin/getCustomerReport',
    async (params, thunkAPI) => {
         const promises = [
            ConversationApi.conversationByDay(params),
            PlanApi.statistic(),
            MessageApi.adminReport(params)]
        const [conversationReport, planReport, messageReport] = await Promise.all(promises)

        return {conversationReport, planReport, messageReport}
    }
);

export const adminSlice = createSlice({
    name: "admin",
    initialState: {
       conversationReport : [],
       planReport : [],
       messageReport :[]
    },

    extraReducers : builder => {
        builder.addCase(thunkGetAdminReport.fulfilled, (state, action) => {
            state.conversationReport = action.payload.conversationReport.conversationReport
            state.planReport = action.payload.planReport.planReport
            state.messageReport = action.payload.messageReport.messageReport
        })
    }
});

export const {reducer, actions} = adminSlice

export default reducer 