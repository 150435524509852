import AxiosClient from "./axiosClient";

const PlanApi = {
    addPlan: (params) => {
        const url = "plan/add"
        return AxiosClient.post(url, params)
    },
    getListPlan: (params) => {
        const url = "plan/list"
        return AxiosClient.get(url, params)
    },
    updatePlan: (params) => {
        const url = "plan/update"
        return AxiosClient.put(url, params)
    },
    statistic : (params) => {
        const url = "plan/statistic"
        return AxiosClient.get(url)
    },
}

export default PlanApi