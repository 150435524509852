import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {REHYDRATE} from "redux-persist"
import ConversationSessionApi from "../api/conversationSessionApi";
import ChatbotApi from "../api/chatbotApi";
import CustomerApi from "../api/customerApi";
import TagApi from "../api/tagApi";
import ProblemApi from "../api/problemApi";
import UserHelper from "../general/helper/userHelper";
import ConversationEvaluatationApi from "../api/conversationEvalutionApi";

export const thunkSupportOverview = createAsyncThunk("report/supportOverview", (params, thunkAPI) => {
    return ConversationSessionApi.overview(params)
})

export const thunkGetSummarize = createAsyncThunk("report/getSummarize", (params, thunkAPI) => {
    return ChatbotApi.summarize(params)
})

export const thunkTopCustomers = createAsyncThunk("report/topCustomers", (params, thunkAPI) => {
    return CustomerApi.top(params)
})

export const thunkViewSummarize = createAsyncThunk("report/viewSummarize", (params, thunkAPI) => {
    return ChatbotApi.viewSummarize(params)
})

export const thunkExcelSummarizeReport = createAsyncThunk("report/excelSummarizeReport", async (params, thunkAPI) => {
    try {
        const {from, to} = params;
        const date = `${UserHelper.formatDatetime(from)} - ${UserHelper.formatDatetime(to)}`
        const buffer = await ChatbotApi.summarizeReport(params)
        const blob = new Blob([buffer], {type: 'application/octet-stream'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Báo cáo tổng hợp ${date}.xlsx`;
        link.click();
        return {}
    } catch (e) {
    }
})

export const thunkCsvSummarizeReport = createAsyncThunk("report/csvSummarizeReport", async (params, thunkAPI) => {
    try {
        const {from, to} = params;
        const date = `${UserHelper.formatDatetime(from)} - ${UserHelper.formatDatetime(to)}`
        const buffer = await ChatbotApi.summarizeReport(params)
        const blob = new Blob([buffer], {type: 'text/csv'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Báo cáo tổng hợp ${date}.csv`;
        link.click();
        return {}
    } catch (e) {
    }
})

export const thunkExcelSummarizeReportBrief = createAsyncThunk("report/excelSummarizeReportBrief", async (params, thunkAPI) => {
    try {
        const {from, to} = params;
        const date = `${UserHelper.formatDatetime(from)} - ${UserHelper.formatDatetime(to)}`
        const buffer = await ChatbotApi.summarizeReportBrief(params)
        const blob = new Blob([buffer], {type: 'application/octet-stream'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Báo cáo hỗ trợ khách hàng ${date}.xlsx`;
        link.click();
        return {}
    } catch (e) {
    }
})

export const thunkCsvSummarizeReportBrief = createAsyncThunk("report/csvSummarizeReportBrief", async (params, thunkAPI) => {
    try {
        const {from, to} = params;
        const date = `${UserHelper.formatDatetime(from)} - ${UserHelper.formatDatetime(to)}`
        const buffer = await ChatbotApi.summarizeReportBrief(params)
        const blob = new Blob([buffer], {type: 'text/csv'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Báo cáo hỗ trợ khách hàng ${date}.csv`;
        link.click();
        return {}
    } catch (e) {
    }
})

export const thunkGetKPIReport = createAsyncThunk("report/getKPIReport", (params, thunkAPI) => {
    return ChatbotApi.getKPI(params)
})

export const thunkExcelKPIReport = createAsyncThunk("report/excelKPIReport", async (params, thunkAPI) => {
    try {
        const {from, to} = params;
        const date = `${UserHelper.formatDatetime(from)} - ${UserHelper.formatDatetime(to)}`
        const buffer = await ChatbotApi.kpiReport(params)
        const blob = new Blob([buffer], {type: 'application/octet-stream'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Báo cáo KPI nhân viên ${date}.xlsx`;
        link.click();
        return {}
    } catch (e) {
    }
})

export const thunkCsvKPIReport = createAsyncThunk("report/csvKPIReport", async (params, thunkAPI) => {
    try {
        const {from, to} = params;
        const date = `${UserHelper.formatDatetime(from)} - ${UserHelper.formatDatetime(to)}`
        const buffer = await ChatbotApi.kpiReport(params)
        const blob = new Blob([buffer], {type: 'text/csv'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Báo cáo KPI nhân viên ${date}.csv`;
        link.click();
        return {}
    } catch (e) {
    }
})

export const thunkViewCustomerReport = createAsyncThunk("report/viewCustomerReport", (params, thunkAPI) => {
    return ChatbotApi.viewCustomerReport(params)
})

export const thunkExcelCustomerReport = createAsyncThunk("report/excelCustomerReport", async (params, thunkAPI) => {
    try {
        const {from, to} = params;
        const date = `${UserHelper.formatDatetime(from)} - ${UserHelper.formatDatetime(to)}`
        const buffer = await ChatbotApi.customerReport(params)
        const blob = new Blob([buffer], {type: 'application/octet-stream'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Báo cáo lượng khách hàng ${date}.xlsx`;
        link.click();
        return {}
    } catch (e) {
    }
})

export const thunkCsvCustomerReport = createAsyncThunk("report/csvCustomerReport", async (params, thunkAPI) => {
    try {
        const {from, to} = params;
        const date = `${UserHelper.formatDatetime(from)} - ${UserHelper.formatDatetime(to)}`
        const buffer = await ChatbotApi.customerReport(params)
        const blob = new Blob([buffer], {type: 'text/csv'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Báo cáo lượng khách hàng ${date}.csv`;
        link.click();
        return {}
    } catch (e) {
    }
})

export const thunkReportTag = createAsyncThunk("report/tagReport", (params, thunkAPI) => {
    return TagApi.reportTag(params)
})

export const thunkViewConversationReport = createAsyncThunk("report/viewConversationReport", (params, thunkAPI) => {
    return ChatbotApi.viewConversationReport(params)
})

export const thunkExcelConversationReport = createAsyncThunk("report/excelConversationReport", async (params, thunkAPI) => {
    try {
        const {from, to} = params;
        const date = `${UserHelper.formatDatetime(from)} - ${UserHelper.formatDatetime(to)}`
        const buffer = await ChatbotApi.conversationReport(params)
        const blob = new Blob([buffer], {type: 'application/octet-stream'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Báo cáo cuộc chat ${date}.xlsx`;
        link.click();
        return {}
    } catch (e) {
    }
})

export const thunkCsvConversationReport = createAsyncThunk("report/csvConversationReport", async (params, thunkAPI) => {
    try {
        const {from, to} = params;
        const date = `${UserHelper.formatDatetime(from)} - ${UserHelper.formatDatetime(to)}`
        const buffer = await ChatbotApi.conversationReport(params)
        const blob = new Blob([buffer], {type: 'text/csv'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Báo cáo cuộc chat ${date}.csv`;
        link.click();
        return {}
    } catch (e) {
    }
})

export const thunkViewMessageReport = createAsyncThunk("report/viewMessageReport", (params, thunkAPI) => {
    return ChatbotApi.viewMessageReport(params)
})

export const thunkExcelMessageReport = createAsyncThunk("report/excelMessageReport", async (params, thunkAPI) => {
    try {
        const {from, to} = params;
        const date = `${UserHelper.formatDatetime(from)} - ${UserHelper.formatDatetime(to)}`
        const buffer = await ChatbotApi.messageReport(params)
        const blob = new Blob([buffer], {type: 'application/octet-stream'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Báo cáo chi tiết tin nhắn ${date}.xlsx`;
        link.click();
        return {}
    } catch (e) {
    }
})

export const thunkCsvMessageReport = createAsyncThunk("report/csvMessageReport", async (params, thunkAPI) => {
    try {
        const {from, to} = params;
        const date = `${UserHelper.formatDatetime(from)} - ${UserHelper.formatDatetime(to)}`
        const buffer = await ChatbotApi.messageReport(params)
        const blob = new Blob([buffer], {type: 'text/csv'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Báo cáo chi tiết tin nhắn ${date}.csv`;
        link.click();
        return {}
    } catch (e) {
    }
})

export const thunkGetCategory = createAsyncThunk("report/getCategory", (params, thunkAPI) => {
    return ConversationSessionApi.category(params)
})

export const thunkViewTestReport = createAsyncThunk("report/viewTestReport", (params, thunkAPI) => {
    return ChatbotApi.viewTestReport(params)
})

export const thunkExcelTestReport = createAsyncThunk("report/excelTestReport", async (params, thunkAPI) => {
    try {
        const {from, to} = params;
        const date = `${UserHelper.formatDatetime(from)} - ${UserHelper.formatDatetime(to)}`
        const buffer = await ChatbotApi.testReport(params)
        const blob = new Blob([buffer], {type: 'application/octet-stream'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Báo cáo kiểm thử ${date}.xlsx`;
        link.click();
        return {}
    } catch (e) {
    }
})

export const thunkCsvTestReport = createAsyncThunk("report/csvTestReport", async (params, thunkAPI) => {
    try {
        const {from, to} = params;
        const date = `${UserHelper.formatDatetime(from)} - ${UserHelper.formatDatetime(to)}`
        const buffer = await ChatbotApi.testReport(params)
        const blob = new Blob([buffer], {type: 'text/csv'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Báo cáo kiểm thử ${date}.csv`;
        link.click();
        return {}
    } catch (e) {
    }
})



export const thunkExcelDailyTestReport = createAsyncThunk("report/excelDailyTestReport", async (params, thunkAPI) => {
    try {
        const {from, to} = params;
        const date = `${UserHelper.formatDatetime(from)} - ${UserHelper.formatDatetime(to)}`
        const buffer = await ChatbotApi.dailyTestReport(params)
        const blob = new Blob([buffer], {type: 'application/octet-stream'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Báo cáo kiểm thử ${date}.xlsx`;
        link.click();
        return {}
    } catch (e) {
    }
})

export const thunkCsvDailyTestReport = createAsyncThunk("report/csvDailyTestReport", async (params, thunkAPI) => {
    try {
        const {from, to} = params;
        const date = `${UserHelper.formatDatetime(from)} - ${UserHelper.formatDatetime(to)}`
        const buffer = await ChatbotApi.dailyTestReport(params)
        const blob = new Blob([buffer], {type: 'text/csv'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Báo cáo kiểm thử ${date}.csv`;
        link.click();
        return {}
    } catch (e) {
    }
})


export const thunkViewLoginReport = createAsyncThunk("report/viewLoginReport", (params, thunkAPI) => {
    return ChatbotApi.viewLoginReport(params)
})

export const thunkExcelLoginReport = createAsyncThunk("report/excelLoginReport", async (params, thunkAPI) => {
    try {
        const {from, to} = params;
        const date = `${UserHelper.formatDatetime(from)} - ${UserHelper.formatDatetime(to)}`
        const buffer = await ChatbotApi.loginReport(params)
        const blob = new Blob([buffer], {type: 'application/octet-stream'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Báo cáo lịch sử đăng nhập ${date}.xlsx`;
        link.click();
        return {}
    } catch (e) {
    }
})

export const thunkCsvLoginReport = createAsyncThunk("report/csvLoginReport", async (params, thunkAPI) => {
    try {
        const {from, to} = params;
        const date = `${UserHelper.formatDatetime(from)} - ${UserHelper.formatDatetime(to)}`
        const buffer = await ChatbotApi.loginReport(params)
        const blob = new Blob([buffer], {type: 'text/csv'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Báo cáo lịch sử đăng nhập ${date}.csv`;
        link.click();
        return {}
    } catch (e) {
    }
})

export const thunkCsvTagReport = createAsyncThunk("report/csvTagReport", async (params, thunkAPI) => {
    try {
        const {from, to} = params;
        const date = `${UserHelper.formatDatetime(from)} - ${UserHelper.formatDatetime(to)}`
        const buffer = await TagApi.tagReport(params)
        const blob = new Blob([buffer], {type: 'text/csv'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Báo cáo phiên chat liên quan ${date}.csv`;
        link.click();
        return {}
    } catch (e) {
    }
})

export const thunkExcelTagReport = createAsyncThunk("report/excelTagReport", async (params, thunkAPI) => {
    try {
        const {from, to} = params;
        const date = `${UserHelper.formatDatetime(from)} - ${UserHelper.formatDatetime(to)}`
        const buffer = await TagApi.tagReport(params)
        const blob = new Blob([buffer], {type: 'application/octet-stream'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Báo cáo phiên chat liên quan ${date}.xlsx`;
        link.click();
        return {}
    } catch (e) {
    }
})


export const thunkCsvConversationEvaluationReport = createAsyncThunk("report/csvConversationEvaluationReport", async (params, thunkAPI) => {
    try {
        const {from, to} = params;
        const date = `${UserHelper.formatDatetime(from)} - ${UserHelper.formatDatetime(to)}`
        const buffer = await ConversationEvaluatationApi.exportReport(params)
        const blob = new Blob([buffer], {type: 'text/csv'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Báo cáo đánh giá phiên chat ${date}.csv`;
        link.click();
        return {}
    } catch (e) {
    }
})

export const thunkExcelConversationEvaluationReport = createAsyncThunk("report/excelConversationEvaluationReport", async (params, thunkAPI) => {
    try {
        const {from, to} = params;
        const date = `${UserHelper.formatDatetime(from)} - ${UserHelper.formatDatetime(to)}`
        const buffer = await ConversationEvaluatationApi.exportReport(params)
        const blob = new Blob([buffer], {type: 'application/octet-stream'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Báo cáo đánh giá phiên chat ${date}.xlsx`;
        link.click();
        return {}
    } catch (e) {
    }
})


export const thunkCsvConversationEvaluationReportByGroup = createAsyncThunk("report/csvConversationEvaluationReportByGroup", async (params, thunkAPI) => {
    try {
        const {from, to} = params;
        const date = `${UserHelper.formatDatetime(from)} - ${UserHelper.formatDatetime(to)}`
        const buffer = await ConversationEvaluatationApi.exportReportByGroup(params)
        const blob = new Blob([buffer], {type: 'text/csv'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Báo cáo phiên chat theo phân nhóm ${date}.csv`;
        link.click();
        return {}
    } catch (e) {
    }
})

export const thunkExcelConversationEvaluationReportByGroup = createAsyncThunk("report/excelConversationEvaluationReportByGroup", async (params, thunkAPI) => {
    try {
        const {from, to} = params;
        const date = `${UserHelper.formatDatetime(from)} - ${UserHelper.formatDatetime(to)}`
        const buffer = await ConversationEvaluatationApi.exportReportByGroup(params)
        const blob = new Blob([buffer], {type: 'application/octet-stream'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Báo cáo phiên chat theo phân nhóm ${date}.xlsx`;
        link.click();
        return {}
    } catch (e) {
    }
})

export const thunkModifiedMessageReport = createAsyncThunk("report/modifiedMessageReport", (params, thunkAPI) => {
    return ChatbotApi.viewMofiiedMessageReport(params)
})

export const thunkExcelModifiedMessageReport = createAsyncThunk("report/excelModifiedMessageReport", async (params, thunkAPI) => {
    try {
        const {from, to} = params;
        const date = `${UserHelper.formatDatetime(from)} - ${UserHelper.formatDatetime(to)}`
        const buffer = await ChatbotApi.modifiedMessageReport(params)
        const blob = new Blob([buffer], {type: 'application/octet-stream'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Báo cáo tin nhắn sửa đổi ${date}.xlsx`;
        link.click();
        return {}
    } catch (e) {
    }
})

export const thunkCsvModifiedMessageReport = createAsyncThunk("report/csvModifiedMessageReport", async (params, thunkAPI) => {
    try {
        const {from, to} = params;
        const date = `${UserHelper.formatDatetime(from)} - ${UserHelper.formatDatetime(to)}`
        const buffer = await ChatbotApi.modifiedMessageReport(params)
        const blob = new Blob([buffer], {type: 'text/csv'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Báo cáo tin nhắn sửa đổi ${date}.csv`;
        link.click();
        return {}
    } catch (e) {
    }
})


export const thunkConversationFromTesterReport = createAsyncThunk("report/conversationFromTesterReport", (params, thunkAPI) => {
    return ChatbotApi.conversationFromTesterReport(params)
})


export const thunkExcelConversationFromTesterReport = createAsyncThunk("report/excelConversationFromTesterReport", async (params, thunkAPI) => {
    try {
        const {from, to} = params;
        const date = `${UserHelper.formatDatetime(from)} - ${UserHelper.formatDatetime(to)}`
        const buffer = await ChatbotApi.conversationFromTesterReport(params)
        const blob = new Blob([buffer], {type: 'application/octet-stream'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Báo cáo cuộc chat từ tester ${date}.xlsx`;
        link.click();
        return {}
    } catch (e) {
    }
})

export const thunkCsvConversationFromTesterReport = createAsyncThunk("report/csvConversationFromTesterReport", async (params, thunkAPI) => {
    try {
        const {from, to} = params;
        const date = `${UserHelper.formatDatetime(from)} - ${UserHelper.formatDatetime(to)}`
        const buffer = await ChatbotApi.conversationFromTesterReport(params)
        const blob = new Blob([buffer], {type: 'text/csv'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Báo cáo cuộc chat từ tester ${date}.csv`;
        link.click();
        return {}
    } catch (e) {
    }
})




export const thunkViewAIReport = createAsyncThunk("report/viewAIReport", (params, thunkAPI) => {
    return ProblemApi.aiReport(params)
})

export const thunkGetTopProblem = createAsyncThunk("report/getTopProblem", (params, thunkAPI) => {
    return ProblemApi.top(params)
})

export const thunkViewConversationEvaluationReport = createAsyncThunk("report/viewConversattionEvaluationReport", (params, thunkAPI) => {
    return ConversationEvaluatationApi.report(params)
})

export const thunkViewConversationEvaluationReportByGroup = createAsyncThunk("report/viewConversattionEvaluationReportByGroup", (params, thunkAPI) => {
    return ConversationEvaluatationApi.reportByGroup(params)
})

export const reportSlice = createSlice({
    name: "report",
    initialState: {
        supportOverview: {
            total: {
                length : 0,
                sessions : []
            },
            requestConversationSession: {
                length : 0,
                sessions : []
            },
            supportConversationSession: 0,
            missingConversationSession: {
                length : 0,
                sessions : []
            },
            serviceRate: 0,
            delay: 0,
            agentMessage: {
                length : 0,
                messages : []
            },
            averageDuration: 0,
            reflection: {
                length : 0,
                sessions : []
            },
            feedback: {
                length : 0,
                sessions : []
            },
            scenarioBuilding: 0,
            botMessage: {
                length : 0,
                messages : []
            },
            testMessage: 0,
            reportMessage: 0,
            situation: 0,
            negative: {
                plan: 0,
                promotion: 0,
                internationalRoaming: 0,
                productAndService: 0,
                other: 0
            }
        },
        summarize: {
            totalCustomer: {},
            facebookCustomer: {},
            inboxFacebookCustomer: {},
            wallFacebookCustomer: {},
            websiteCustomer: {},
            appCustomer: {},
            cMSCustomer: {},
            totalAgentMessage: {},
            facebookAgentMessage: {},
            websiteAgentMessage: {},
            appAgentMessage: {},
            cMSAgentMessage: {},
            negative: {},
            averageReply: {},
            serviceRate: {},
            delay: {},
            feedback: {},
        },
        allSummarize: {
            totalCustomer: {},
            facebookCustomer: {},
            inboxFacebookCustomer: {},
            wallFacebookCustomer: {},
            websiteCustomer: {},
            appCustomer: {},
            cMSCustomer: {},
            totalAllSession: {},
            totalSession: {},
            facebookSession: {},
            websiteSession: {},
            appSession: {},
            totalBotSession: {},
            facebookBotSession: {},
            websiteBotSession: {},
            appBotSession: {},
            totalAgentSession: {},
            facebookAgentSession: {},
            websiteAgentSession: {},
            appAgentSession: {},
            totalAgentMessage: {},
            facebookAgentMessage: {},
            websiteAgentMessage: {},
            appAgentMessage: {},
            cMSAgentMessage: {},
            negative: {},
            averageReply: {},
            serviceRate: {},
            delay: {},
            feedback: {},
        },
        topCustomers: [],
        kPIs: [],
        customerReports: [],
        tagReport: {
            taggedMessage: {
                tagPercentages: [],
                nuancePercentages: [],
                totalCount: 0
            },
            taggedSession: []
        },
        conversationReports: [],
        messageReports: [],
        modifiedMessageReports: [],
        chatsFromTesterReport: [],
        category: {
            plan: 0,
            service: {
                network: 0,
                charge: 0,
                productService: 0,
                total: 0
            },
            promotion: 0,
            professional: 0,
            internationalRoaming: 0,
            other: 0
        },
        testReports: {
            scenarioBuilding: {},
            modifiedScenario: {},
            newScenario: {},
            testMessage: {},
            modifiedMessage: {},
            joinedTesterConversationSession: {},
            situation: {},
            modifiedSituation: {},
            testSituation: {},
            appSystem: {},
            webSystem: {},
            facebookSystem: {}
        },
        loginReports: [],
        aiReport: {
            sessions: 0,
            botSessions: 0,
            agentSessions: 0,
            plan: {
                percent: 0,
                botSessions: 0,
                agentSessions: 0,
                sessions: 0,
                problems: []
            },
            serviceProduct: {
                percent: 0,
                botSessions: 0,
                agentSessions: 0,
                sessions: 0,
                network: {
                    sessions: 0, percent: 0, problems: []
                },
                charges: {
                    sessions: 0, percent: 0, problems: []
                },
                serviceProduct: {
                    sessions: 0, percent: 0, problems: []
                }
            },
            promotion: {
                percent: 0,
                botSessions: 0,
                agentSessions: 0,
                sessions: 0,
                problems: []
            },
            professional: {
                percent: 0,
                botSessions: 0,
                agentSessions: 0,
                sessions: 0,
                problems: []
            },
            international: {
                percent: 0,
                botSessions: 0,
                agentSessions: 0,
                sessions: 0,
                problems: []
            },
            other: {
                percent: 0,
                botSessions: 0,
                agentSessions: 0,
                sessions: 0,
                problems: []
            },
            conversationEvaluationReport: {
                allConversationSession : {},
                allBotSession : {},
                allAgentSession : {},
                negativeResponse : {},
                positiveResponse : {},
                satisfiedResponse : {},
                notRespondResponse :{},
                rudeResponse :{},
                negativeAndNotRespondResponse : {},
                feedbackResponse :{},
                otherResponse :{},
            },
            conversationEvaluationReportByGroup : {
                packOfData : {},
                productService : {} ,
                professionalInformation : {},
                promotion : {},
                internationRoaming : {},
                other : {},
            }
        },
        topProblems: []
    }
    , reducers: {},
    extraReducers: builder => {
        builder.addCase(REHYDRATE, (state, action) => {

            const {payload} = action
            if (payload && payload.report) {
                const {report} = payload
                state.supportOverview = report?.supportOverview
                state.summarize = report?.summarize
                state.allSummarize = report?.allSummarize
                state.topCustomers = report?.topCustomers
                state.kPIs = report?.kPIs
                state.customerReports = report?.customerReports
                state.tagReport = report?.tagReport
                state.conversationReports = report?.conversationReports
                state.messageReports = report?.messageReports
                state.category = report?.category
                state.chatbotIndicator = report?.chatbotIndicator
                state.testReports = report?.testReports
                state.loginReports = report?.loginReports
                state.aiReport = report?.aiReport
                state.topProblems = report?.topProblems
                state.modifiedMessageReports = report?.modifiedMessageReports
            }
            state.isGettingSummarizeReport = false
            state.isExportingExcelSummarize = false
            state.isExportingCsvSummarize = false
            state.isGettingKPIReport = false
            state.isExportingExcelKPI = false
            state.isExportingCsvKPI = false
            state.isGettingCustomerReport = false
            state.isExportingExcelCustomer = false
            state.isExportingCsvCustomer = false
            state.isExportingExcelSummarizeBrief = false
            state.isExportingCsvSummarizeBrief = false
            state.isGetitngConversationReport = false
            state.isExportingExcelConversation = false
            state.isExportingCsvConversation = false
            state.isGettingMessageReport = false
            state.isExportingExcelMessage = false
            state.isExportingCsvMessage = false
            state.isGettingChatbotIndicator = false
            state.isUpdatingChatbotIndicator = false
            state.isGettingTestReport = false
            state.isExportingExcelTest = false
            state.isExportingCsvTest = false
            state.isExportingExcelDailyTest = false
            state.isExportingCsvDailyTest = false
            state.isGettingLoginReport = false
            state.isExportingExcelLogin = false
            state.isExportingCsvLogin = false
            state.isGettingAIReport = false
            state.isGettingTopProblem = false
            state.isExportingExcelTag = false
            state.isExportingCsvTag = false
            state.isGettingConversationEvaluationReport = false
            state.isExportingConversationEvaluationReportExcel = false
            state.isExportingConversationEvaluationReportCsv = false
            state.isGettingConversationEvaluationReportByGroup = false
            state.isExportingConversationEvaluationReportByGroupExcel = false
            state.isExportingConversationEvaluationReportByGroupCsv = false
            state.isGettingModifiedMessageReport = false
            state.isExportingModifiedMessageReportExcel = false
            state.isExportingModifiedMessageReportCsv = false
            state.isGettingChatsFromTesterReport = false
            state.isExportingChatsFromTesterReportExcel = false
            state.isExportingChatsFromTesterReportCsv = false
            state.isGettingSupportOverview = true
        })

        builder.addCase(thunkSupportOverview.pending, (state, action) => {
            state.isGettingSupportOverview = true
        })

        builder.addCase(thunkSupportOverview.fulfilled, (state, action) => {
            const {payload} = action
            state.isGettingSupportOverview = false
            state.supportOverview = payload
        })

        builder.addCase(thunkGetSummarize.fulfilled, (state, action) => {
            const {payload} = action
            const {summarize} = payload
            state.summarize = summarize
        })

        builder.addCase(thunkTopCustomers.fulfilled, (state, action) => {
            const {payload} = action
            const {topCustomers} = payload
            state.topCustomers = topCustomers
        })

        builder.addCase(thunkViewSummarize.pending, (state, action) => {
            state.isGettingSummarizeReport = true
        })

        builder.addCase(thunkViewSummarize.rejected, (state, action) => {
            state.isGettingSummarizeReport = false
        })

        builder.addCase(thunkViewSummarize.fulfilled, (state, action) => {
            const {payload} = action
            const {summarize} = payload
            state.allSummarize = summarize
            state.isGettingSummarizeReport = false
        })

        builder.addCase(thunkExcelSummarizeReport.pending, state => {
            state.isExportingExcelSummarize = true
        })
        builder.addCase(thunkExcelSummarizeReport.rejected, (state, action) => {
            state.isExportingExcelSummarize = false
        })
        builder.addCase(thunkExcelSummarizeReport.fulfilled, state => {
            state.isExportingExcelSummarize = false
        })

        builder.addCase(thunkCsvSummarizeReport.pending, state => {
            state.isExportingCsvSummarize = true
        })
        builder.addCase(thunkCsvSummarizeReport.rejected, (state, action) => {
            state.isExportingCsvSummarize = false
        })
        builder.addCase(thunkCsvSummarizeReport.fulfilled, state => {
            state.isExportingCsvSummarize = false
        })

        builder.addCase(thunkExcelSummarizeReportBrief.pending, state => {
            state.isExportingExcelSummarizeBrief = true
        })
        builder.addCase(thunkExcelSummarizeReportBrief.rejected, (state, action) => {
            state.isExportingExcelSummarizeBrief = false
        })
        builder.addCase(thunkExcelSummarizeReportBrief.fulfilled, state => {
            state.isExportingExcelSummarizeBrief = false
        })

        builder.addCase(thunkCsvSummarizeReportBrief.pending, state => {
            state.isExportingCsvSummarizeBrief = true
        })
        builder.addCase(thunkCsvSummarizeReportBrief.rejected, (state, action) => {
            state.isExportingCsvSummarizeBrief = false
        })
        builder.addCase(thunkCsvSummarizeReportBrief.fulfilled, state => {
            state.isExportingCsvSummarizeBrief = false
        })

        builder.addCase(thunkGetKPIReport.pending, (state, action) => {
            state.isGettingKPIReport = true
        })

        builder.addCase(thunkGetKPIReport.rejected, (state, action) => {
            state.isGettingKPIReport = false
        })

        builder.addCase(thunkGetKPIReport.fulfilled, (state, action) => {
            const {payload} = action
            const {kPIs} = payload
            state.kPIs = kPIs
            state.isGettingKPIReport = false
        })

        builder.addCase(thunkExcelKPIReport.pending, state => {
            state.isExportingExcelKPI = true
        })
        builder.addCase(thunkExcelKPIReport.rejected, (state, action) => {
            state.isExportingExcelKPI = false
        })
        builder.addCase(thunkExcelKPIReport.fulfilled, state => {
            state.isExportingExcelKPI = false
        })

        builder.addCase(thunkCsvKPIReport.pending, state => {
            state.isExportingCsvKPI = true
        })
        builder.addCase(thunkCsvKPIReport.rejected, (state, action) => {
            state.isExportingCsvKPI = false
        })
        builder.addCase(thunkCsvKPIReport.fulfilled, state => {
            state.isExportingCsvKPI = false
        })

        builder.addCase(thunkViewCustomerReport.pending, (state, action) => {
            state.isGettingCustomerReport = true
        })

        builder.addCase(thunkViewCustomerReport.rejected, (state, action) => {
            state.isGettingCustomerReport = false
        })

        builder.addCase(thunkViewCustomerReport.fulfilled, (state, action) => {
            const {payload} = action
            const {customerReports} = payload
            state.customerReports = customerReports
            state.isGettingCustomerReport = false
        })

        builder.addCase(thunkExcelCustomerReport.pending, state => {
            state.isExportingExcelCustomer = true
        })
        builder.addCase(thunkExcelCustomerReport.rejected, (state, action) => {
            state.isExportingExcelCustomer = false
        })
        builder.addCase(thunkExcelCustomerReport.fulfilled, state => {
            state.isExportingExcelCustomer = false
        })

        builder.addCase(thunkCsvCustomerReport.pending, state => {
            state.isExportingCsvCustomer = true
        })
        builder.addCase(thunkCsvCustomerReport.rejected, (state, action) => {
            state.isExportingCsvCustomer = false
        })
        builder.addCase(thunkCsvCustomerReport.fulfilled, state => {
            state.isExportingCsvCustomer = false
        })

        builder.addCase(thunkReportTag.fulfilled, (state, action) => {
            const {payload} = action
            state.tagReport.taggedMessage = payload.taggedMessage
            state.tagReport.taggedSession = payload.taggedSession
        })

        builder.addCase(thunkViewConversationReport.pending, (state, action) => {
            state.isGettingConversationReport = true
        })

        builder.addCase(thunkViewConversationReport.rejected, (state, action) => {
            state.isGettingConversationReport = false
        })

        builder.addCase(thunkViewConversationReport.fulfilled, (state, action) => {
            const {payload} = action
            const {conversationReports} = payload
            state.conversationReports = conversationReports
            state.isGettingConversationReport = false
        })

        builder.addCase(thunkExcelConversationReport.pending, state => {
            state.isExportingExcelConversation = true
        })
        builder.addCase(thunkExcelConversationReport.rejected, (state, action) => {
            state.isExportingExcelConversation = false
        })
        builder.addCase(thunkExcelConversationReport.fulfilled, state => {
            state.isExportingExcelConversation = false
        })

        builder.addCase(thunkCsvConversationReport.pending, state => {
            state.isExportingCsvConversation = true
        })
        builder.addCase(thunkCsvConversationReport.rejected, (state, action) => {
            state.isExportingCsvConversation = false
        })
        builder.addCase(thunkCsvConversationReport.fulfilled, state => {
            state.isExportingCsvConversation = false
        })

        builder.addCase(thunkViewMessageReport.pending, state => {
            state.isGettingMessageReport = true
        })
        builder.addCase(thunkViewMessageReport.rejected, (state, action) => {
            state.isGettingMessageReport = false
        })
        builder.addCase(thunkViewMessageReport.fulfilled, (state, action) => {
            const {payload} = action
            const {messageReports} = payload
            state.messageReports = messageReports
            state.isGettingMessageReport = false
        })

        builder.addCase(thunkExcelMessageReport.pending, state => {
            state.isExportingExcelMessage = true
        })
        builder.addCase(thunkExcelMessageReport.rejected, (state, action) => {
            state.isExportingExcelMessage = false
        })
        builder.addCase(thunkExcelMessageReport.fulfilled, (state, action) => {
            state.isExportingExcelMessage = false
        })

        builder.addCase(thunkCsvMessageReport.pending, state => {
            state.isExportingCsvMessage = true
        })
        builder.addCase(thunkCsvMessageReport.rejected, (state, action) => {
            state.isExportingCsvMessage = false
        })
        builder.addCase(thunkCsvMessageReport.fulfilled, (state, action) => {
            state.isExportingCsvMessage = false
        })

        builder.addCase(thunkGetCategory.fulfilled, (state, action) => {
            const {payload} = action
            const {category} = payload
            state.category = category
        })

        builder.addCase(thunkViewTestReport.pending, state => {
            state.isGettingTestReport = true
        })
        builder.addCase(thunkViewTestReport.rejected, (state, action) => {
            state.isGettingTestReport = false
        })
        builder.addCase(thunkViewTestReport.fulfilled, (state, action) => {
            const {payload} = action
            const {testReport} = payload
            state.testReport = testReport
            state.isGettingTestReport = false
        })

        builder.addCase(thunkExcelTestReport.pending, state => {
            state.isExportingExcelTest = true
        })
        builder.addCase(thunkExcelTestReport.rejected, (state, action) => {
            state.isExportingExcelTest = false
        })
        builder.addCase(thunkExcelTestReport.fulfilled, (state, action) => {
            state.isExportingExcelTest = false
        })

        builder.addCase(thunkCsvTestReport.pending, state => {
            state.isExportingCsvTest = true
        })
        builder.addCase(thunkCsvTestReport.rejected, (state, action) => {
            state.isExportingCsvTest = false
        })
        builder.addCase(thunkCsvTestReport.fulfilled, (state, action) => {
            state.isExportingCsvTest = false
        })

        builder.addCase(thunkExcelDailyTestReport.pending, state => {
            state.isExportingExcelDailyTest = true
        })
        builder.addCase(thunkExcelDailyTestReport.rejected, (state, action) => {
            state.isExportingExcelDailyTest = false
        })
        builder.addCase(thunkExcelDailyTestReport.fulfilled, (state, action) => {
            state.isExportingExcelDailyTest = false
        })

        builder.addCase(thunkCsvDailyTestReport.pending, state => {
            state.isExportingCsvDailyTest = true
        })
        builder.addCase(thunkCsvDailyTestReport.rejected, (state, action) => {
            state.isExportingCsvDailyTest = false
        })
        builder.addCase(thunkCsvDailyTestReport.fulfilled, (state, action) => {
            state.isExportingCsvDailyTest = false
        })

        builder.addCase(thunkViewLoginReport.pending, state => {
            state.isGettingLoginReport = true
        })
        builder.addCase(thunkViewLoginReport.rejected, (state, action) => {
            state.isGettingLoginReport = false
        })
        builder.addCase(thunkViewLoginReport.fulfilled, (state, action) => {
            const {payload} = action
            const {loginReports} = payload
            state.loginReports = loginReports
            state.isGettingLoginReport = false
        })

        builder.addCase(thunkExcelLoginReport.pending, state => {
            state.isExportingExcelLogin = true
        })
        builder.addCase(thunkExcelLoginReport.rejected, (state, action) => {
            state.isExportingExcelLogin = false
        })
        builder.addCase(thunkExcelLoginReport.fulfilled, (state, action) => {
            state.isExportingExcelLogin = false
        })

        builder.addCase(thunkCsvLoginReport.pending, state => {
            state.isExportingCsvLogin = true
        })
        builder.addCase(thunkCsvLoginReport.rejected, (state, action) => {
            state.isExportingCsvLogin = false
        })
        builder.addCase(thunkCsvLoginReport.fulfilled, (state, action) => {
            state.isExportingCsvLogin = false
        })

        builder.addCase(thunkViewAIReport.pending, state => {
            state.isGettingAIReport = true
        })
        builder.addCase(thunkViewAIReport.rejected, (state, action) => {
            state.isGettingAIReport = false
        })
        builder.addCase(thunkViewAIReport.fulfilled, (state, action) => {
            const {payload} = action
            const {aiReport} = payload
            state.aiReport = aiReport
            state.isGettingAIReport = false
        })

        builder.addCase(thunkGetTopProblem.pending, state => {
            state.isGettingTopProblem = true
        })
        builder.addCase(thunkGetTopProblem.rejected, (state, action) => {
            state.isGettingTopProblem = false
        })
        builder.addCase(thunkGetTopProblem.fulfilled, (state, action) => {
            const {payload} = action
            const {topProblems} = payload
            state.topProblems = topProblems
            state.isGettingTopProblem = false
        })
        builder.addCase(thunkExcelTagReport.pending, state => {
            state.isExportingExcelMessage = true
        })
        builder.addCase(thunkExcelTagReport.rejected, (state, action) => {
            state.isExportingExcelMessage = false
        })
        builder.addCase(thunkExcelTagReport.fulfilled, (state, action) => {
            state.isExportingExcelMessage = false
        })

        builder.addCase(thunkCsvTagReport.pending, state => {
            state.isExportingExcelMessage = true
        })
        builder.addCase(thunkCsvTagReport.rejected, (state, action) => {
            state.isExportingExcelMessage = false
        })
        builder.addCase(thunkCsvTagReport.fulfilled, (state, action) => {
            state.isExportingExcelMessage = false
        })


        builder.addCase(thunkViewConversationEvaluationReport.pending, state => {
            state.isGettingConversationEvaluationReport = true
        })
        builder.addCase(thunkViewConversationEvaluationReport.rejected, (state, action) => {
            state.isGettingConversationEvaluationReport = false
        })
        builder.addCase(thunkViewConversationEvaluationReport.fulfilled, (state, action) => {
            const {payload} = action
            state.conversationEvaluationReport = payload.data
            state.isGettingConversationEvaluationReport = false
        })

        builder.addCase(thunkExcelConversationEvaluationReport.pending, state => {
            state.isExportingConversationEvaluationReportExcel = true
        })
        builder.addCase(thunkExcelConversationEvaluationReport.rejected, (state, action) => {
            state.isExportingConversationEvaluationReportExcel = false
        })
        builder.addCase(thunkExcelConversationEvaluationReport.fulfilled, (state, action) => {
            state.isExportingConversationEvaluationReportExcel = false
        })

        builder.addCase(thunkCsvConversationEvaluationReport.pending, state => {
            state.isExportingConversationEvaluationReportCsv = true
        })
        builder.addCase(thunkCsvConversationEvaluationReport.rejected, (state, action) => {
            state.isExportingConversationEvaluationReportCsv = false
        })
        builder.addCase(thunkCsvConversationEvaluationReport.fulfilled, (state, action) => {
            state.isExportingConversationEvaluationReportCsv = false
        })


        builder.addCase(thunkViewConversationEvaluationReportByGroup.pending, state => {
            state.isGettingConversationEvaluationReportByGroup = true
        })
        builder.addCase(thunkViewConversationEvaluationReportByGroup.rejected, (state, action) => {
            state.isGettingConversationEvaluationReportByGroup = false
        })
        builder.addCase(thunkViewConversationEvaluationReportByGroup.fulfilled, (state, action) => {
            const {payload} = action
            state.conversationEvaluationReportByGroup = payload.data
            state.isGettingConversationEvaluationReportByGroup = false
        })

        builder.addCase(thunkExcelConversationEvaluationReportByGroup.pending, state => {
            state.isExportingConversationEvaluationReportByGroupExcel = true
        })
        builder.addCase(thunkExcelConversationEvaluationReportByGroup.rejected, (state, action) => {
            state.isExportingConversationEvaluationReportByGroupExcel = false
        })
        builder.addCase(thunkExcelConversationEvaluationReportByGroup.fulfilled, (state, action) => {
            state.isExportingConversationEvaluationReportByGroupExcel = false
        })

        builder.addCase(thunkCsvConversationEvaluationReportByGroup.pending, state => {
            state.isExportingConversationEvaluationReportByGroupCsv = true
        })
        builder.addCase(thunkCsvConversationEvaluationReportByGroup.rejected, (state, action) => {
            state.isExportingConversationEvaluationReportByGroupCsv = false
        })
        builder.addCase(thunkCsvConversationEvaluationReportByGroup.fulfilled, (state, action) => {
            state.isExportingConversationEvaluationReportByGroupCsv = false
        })

        builder.addCase(thunkModifiedMessageReport.pending, state => {
            state.isGettingModifiedMessageReport = true
        })

        builder.addCase(thunkModifiedMessageReport.fulfilled, (state, action) => {
            const {payload} = action
            const {messageModifiedReports} = payload
            state.modifiedMessageReports = messageModifiedReports
            state.isGettingModifiedMessageReport = false
        })

        builder.addCase(thunkExcelModifiedMessageReport.pending, state => {
            state.isExportingExcelMessage = true
        })
        builder.addCase(thunkExcelModifiedMessageReport.rejected, (state, action) => {
            state.isExportingExcelMessage = false
        })

        builder.addCase(thunkExcelModifiedMessageReport.fulfilled, state => {
            state.isExportingExcelMessage = false
        })

        builder.addCase(thunkCsvModifiedMessageReport.pending, state => {
            state.isExportingExcelMessage = true
        })

        builder.addCase(thunkCsvModifiedMessageReport.rejected, (state, action) => {
            state.isExportingExcelMessage = false
        })
        builder.addCase(thunkCsvModifiedMessageReport.fulfilled, state => {
            state.isExportingExcelMessage = false
        })

        builder.addCase(thunkConversationFromTesterReport.fulfilled, (state, action) => {
            const {payload} = action
            state.chatsFromTesterReport = payload
        })

        builder.addCase(thunkExcelConversationFromTesterReport.pending, state => {
            state.isExportingExcelMessage = true
        })

        builder.addCase(thunkExcelConversationFromTesterReport.rejected, (state, action) => {
            state.isExportingExcelMessage = false
        })

        builder.addCase(thunkExcelConversationFromTesterReport.fulfilled, state => {
            state.isExportingExcelMessage = false
        })

        builder.addCase(thunkCsvConversationFromTesterReport.pending, state => {
            state.isExportingExcelMessage = true
        })

        builder.addCase(thunkCsvConversationFromTesterReport.rejected, (state, action) => {
            state.isExportingExcelMessage = false
        })

        builder.addCase(thunkCsvConversationFromTesterReport.fulfilled, state => {
            state.isExportingExcelMessage = false
        })

    }
})
export const {reducer, actions} = reportSlice


export const {} = actions
export default reducer