import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {
    persistReducer,
    persistStore,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    FLUSH
} from "redux-persist"
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2"
import storage from "redux-persist/lib/storage"
import authReducer from "./authSlice"
import settingsReducer from "./settingsSlice";
import accountReducer from "./accountSlice"
import chatbotReducer from "./chatbotSlice"
import chatReducer from "./chatSlice"
import StatisticReducer from "./statisticSlice"
import CustomerReducer from "./customerSlice"
import PlanReducer from "./planSlice"
import ReportedConversationReducer from "./reportedConversationSlice"
import BillingReducer from "./billingSlice"
import reportReducer from "./reportSlice"
import dateTransform from "./dateTransform";
import tagReducer from "./tagSlice";
import adminReducer from "./adminSlice";
import appReducer from "./appSlice";

const rootReducer = combineReducers({
    app: appReducer,
    auth: authReducer,
    settings: settingsReducer,
    account: accountReducer,
    chatbot: chatbotReducer,
    chat: chatReducer,
    statistic: StatisticReducer,
    customer: CustomerReducer,
    plan: PlanReducer,
    reportedConversation: ReportedConversationReducer,
    billing: BillingReducer,
    tag: tagReducer,
    report: reportReducer,
    admin : adminReducer
})

const persistedReducer = persistReducer({
    key: "root",
    storage,
    stateReconciler: autoMergeLevel2,
    transforms: [dateTransform],
    whitelist: ["app", "chatbot", "account", "report"]
}, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            ignoredPaths: [
                'chat.startDate',
                'chat.endDate',
                "statistic.finance.startDate",
                "statistic.finance.endDate",
                "statistic.message.startDate",
                "statistic.message.endDate"
            ],
        }
    })
})

export const persistor = persistStore(store)


